import React from "react"

import {graphql, useStaticQuery} from 'gatsby'

import Layout from '../containers/layout'

import Breadcrumb from '../components/breadcrumb'
import Container from '../components/container'
import MetaSchemaSEO from '../components/seo';



export const query = graphql`
  query {
    contactQuery: strapi{ siteSetting {
      site_name
    }}
    site {
      siteMetadata {
      siteUrl
      }
    }
  }
`


// markup
const ContactPage = () => {
  const data = useStaticQuery(query);
  const domain = data.site.siteMetadata.siteUrl.replace("https://","").replace("www.","")
  const page_metadata = {
    "title_short": "Contact",
    "parent":{
      "slug":"about",
      "title": "About"
    }
  }

  return (
    <Layout>
    <MetaSchemaSEO
      title = "Contact Us"
    />
      <Container>
        <Breadcrumb thisPage={page_metadata}></Breadcrumb>
        <h1>Contact Info</h1>
        <p>Please use the appropriate contact method to expedite your request.</p>
        <div>
            <p><b>For Advertising or Partnerships:</b> <br/>sales@{domain}</p> 
            <p><b>For Content Recommendations:</b> <br/>tips@{domain}</p> 
            <p><b>For All Other Requests:</b> <br/>info@{domain}</p> 

        </div>
      </Container>
    </Layout>
  )
}

export default ContactPage
